import { SiteLocale } from '@graphql/generated';
import { zodResolver } from '@hookform/resolvers/zod';
import { AppCommerceLayerClient } from '@lib/AppCommerceLayerClient';
import t, { tt } from '@lib/locales';
import { trackLogin } from '@lib/utils/gtmUtils';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

type Props = {
  client: AppCommerceLayerClient;
  locale: SiteLocale;
  onSuccess: (email: string) => void;
};

const Login = ({ client, locale, onSuccess }: Props) => {
  const [ui, setUi] = useState({ errorMessage: null, loading: false });

  const schema = z
    .object({
      email: z
        .string()
        .min(1, t(locale, 'field_required'))
        .email(t(locale, 'enter_valid_email'))
        .toLowerCase()
        .trim(),
      password: z.string().min(6, tt(locale, 'password_min_chars', { min: '6' })),
    })
    .required({
      email: true,
      password: true,
    });

  type Schema = z.infer<typeof schema>;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Schema>({
    resolver: zodResolver(schema),
  });

  const onSubmit = async (data: Schema) => {
    setUi({ errorMessage: null, loading: true });
    let errorMessage = null;
    try {
      await client.login(data);
      trackLogin();
      onSuccess(data.email);
    } catch (error) {
      errorMessage = t(locale, 'invalid_credentials');
    } finally {
      setUi({ errorMessage, loading: false });
    }
  };

  return (
    <form className="login" onSubmit={handleSubmit(onSubmit)}>
      {ui.errorMessage && <p className="form_error">{ui.errorMessage}</p>}
      <div className="form_field">
        <label className="form_label">{t(locale, 'email')}</label>
        <input
          type="email"
          placeholder={t(locale, 'email')}
          {...register('email')}
          className="form_input"
          autoComplete="email"
        />
        <p className="form_error">{errors.email?.message}</p>
      </div>
      <div className="form_field">
        <label className="form_label">{t(locale, 'password')}</label>
        <input
          type="password"
          placeholder={t(locale, 'password')}
          {...register('password')}
          className="form_input"
          autoComplete="current-password"
        />
        <p className="form_error">{errors.password?.message}</p>
      </div>
      <button className="button--primary" type="submit" disabled={ui.loading}>
        {t(locale, 'login')}
      </button>
      {ui.loading && <div> {t(locale, 'loading')}</div>}
    </form>
  );
};
export default Login;
