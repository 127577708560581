import { SiteLocale, SiteQuery } from '@graphql/generated';
import { useHaveUser } from '@hooks/use-have-user';
import { useAppContext } from '@lib/context/appContext';
import t from '@lib/locales';
import { usePathname, useRouter } from 'next/navigation';
import { useState } from 'react';
import Login from './Login';
import RecoverPassword from './RecoverPassword';
import Register from './Register';

type Props = {
  locale: SiteLocale;
  data: SiteQuery;
};

export const Authentication = ({ locale, data }: Props) => {
  const { privacy, subscribe, profiling = null } = data;
  const { state, dispatch, client } = useAppContext();
  const { authModal: isActive, baseUrl } = state;
  const router = useRouter();
  const pathname = usePathname();

  const [isLogin, setLogin] = useState(true);
  const [isPwdRecover, setForgorgotPwd] = useState(false);
  const [user, setUser] = useState<string>();

  useHaveUser(setUser);

  const logout = async () => {
    try {
      await client.logout();
      handleClose();
      router.replace('/');
    } catch (error) {
      console.error('logout error', error);
    }
  };

  const handleClose = () => {
    setLogin(true);
    setForgorgotPwd(false);
    dispatch({ type: 'TOGGLE_AUTH_MODAL', data: false });
  };

  const onSuccess = (email?: string) => {
    if (!!email) {
      setUser(email);
    }
    dispatch({ type: 'TOGGLE_FEEDBACK_MODAL_USER', data: client });

    if (pathname.indexOf('checkout') > -1) {
      router.refresh();
      return;
    }
    handleClose();
  };

  return (
    <div aria-label="Authentication">
      <div className={`dialog ${isActive ? 'is-active' : ''}`}>
        <div className="dialog__frame">
          <div className="dialog__header">
            <div className="dialog__close" onClick={handleClose}>
              <img className="dialog__close__icon" src="/images/icons/times.svg" alt="close" />
            </div>
          </div>

          <div className="dialog__body">
            {user && client && (
              <div className="dialog__body__content">
                <span className="dialog__body__content--title">{t(locale, 'logged_as')} </span>
                <span className="dialog__body__content--button">
                  <button className="button--primary" type="button" onClick={() => logout()}>
                    {t(locale, 'logout')}
                  </button>
                </span>
              </div>
            )}

            {!user && (
              <>
                {isLogin && (
                  <>
                    {!isPwdRecover && (
                      <div className="auth">
                        <h4 className="auth__title">{t(locale, 'login')}</h4>
                        <Login client={client} locale={locale} onSuccess={onSuccess} />
                        <button
                          type="button"
                          className="button--small-primary-link"
                          onClick={() => setForgorgotPwd(true)}
                        >
                          {t(locale, 'forgot_password')}
                        </button>
                      </div>
                    )}
                    {isPwdRecover && (
                      <div className="auth">
                        <h4 className="auth__title">{t(locale, 'forgot_password')}</h4>
                        <RecoverPassword baseUrl={baseUrl} client={client} locale={locale} />
                        <button
                          type="button"
                          className="button--small-primary-link"
                          onClick={() => setForgorgotPwd(false)}
                        >
                          {t(locale, 'login_button')}
                        </button>
                      </div>
                    )}
                  </>
                )}
                {!isLogin && (
                  <div className="auth">
                    <h4 className="auth__title">{t(locale, 'register')}</h4>
                    <Register
                      client={client}
                      locale={locale}
                      privacy={privacy}
                      subscribe={subscribe}
                      profiling={profiling}
                      onSuccess={onSuccess}
                    />
                  </div>
                )}
              </>
            )}
          </div>

          <div className="dialog__footer">
            {!user && !isPwdRecover && (
              <button
                className="button--small-primary-link"
                type="button"
                onClick={() => setLogin(!isLogin)}
              >
                {isLogin ? t(locale, 'register_button') : t(locale, 'login_button')}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
