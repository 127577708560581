import { CloseIcon } from '@components/icons/CloseIcon';
import { SiteLocale } from '@graphql/generated';
import { useAppContext } from '@lib/context/appContext';
import t, { Labels } from '@lib/locales';
import { filterMarketByCountryCode, getPathFromMarket } from '@lib/utils/clUtils';
import { deleteCartIdSession, deleteUserSession } from '@utils/local-storage';
import { useRouter, useSearchParams } from 'next/navigation';

type Props = {
  locale: SiteLocale;
};

export const CountryDialog = ({ locale }: Props) => {
  const { state, dispatch, client } = useAppContext();
  const { feedbackModalCountry: result } = state;
  const router = useRouter();
  const searchParams = useSearchParams();

  const redirectToNewCountry = () => {
    // delete cart id and user session because they are related to the market id
    if (client) {
      client.cleanup();
    } else {
      deleteCartIdSession();
      deleteUserSession();
    }
    dispatch({ type: 'SET_ORDER', data: null });
    redirect(result.newCountry);
  };

  function redirectToOldCountry() {
    redirect(result.oldCountry);
  }

  function redirect(countryCode: string) {
    const m = filterMarketByCountryCode(countryCode);
    const marketId = m[0]?.market_id;
    if (!marketId) {
      router.push('/');
      return;
    }
    const redirectUrl = getPathFromMarket(marketId, locale);
    const search = searchParams.toString();
    const url = `${redirectUrl}${search ? `?${search}` : ''}`;
    dispatch({ type: 'TOGGLE_FEEDBACK_MODAL_COUNTRY', data: false });
    dispatch({ type: 'INIT_MARKET', data: { marketId, countryCode } });
    router.push(url);
  }

  if (!result) {
    return null;
  }

  return (
    <div className={`dialog ${result ? 'is-active' : ''} feedback-dialog`}>
      <div className="dialog__frame">
        <div className="dialog__header">
          <div className="dialog__close" onClick={redirectToNewCountry}>
            <CloseIcon className="nav_color_icon" />
          </div>
        </div>

        <div className="dialog__body">
          <div className="dialog__body__content--title">{t(locale, 'confirm_change_country')}</div>
        </div>

        <div className="dialog__footer">
          <div className="feedback-dialog__buttons">
            <a onClick={redirectToOldCountry} className="feedback-dialog__buttons__close">
              {t(locale, 'back_change_country')}:{' '}
              {t(locale, `countries.${result.oldCountry.toUpperCase()}` as Labels)}
            </a>
            <a onClick={redirectToNewCountry} className="feedback-dialog__buttons__cart">
              {t(locale, 'continue_change_country')}:{' '}
              {t(locale, `countries.${result.newCountry.toUpperCase()}` as Labels)}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
