import { SiteLocale, SiteQuery } from '@graphql/generated';
import { zodResolver } from '@hookform/resolvers/zod';
import { AppCommerceLayerClient } from '@lib/AppCommerceLayerClient';
import t, { tt } from '@lib/locales';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { ModularText } from '../contents/ModularText';

type Props = {
  client: AppCommerceLayerClient;
  locale: SiteLocale;
  onSuccess: (email: string) => void;
  privacy: SiteQuery['privacy'];
  subscribe: SiteQuery['subscribe'];
  profiling: SiteQuery['profiling'];
};

const Register = ({ client, locale, onSuccess, privacy, subscribe, profiling }: Props) => {
  const [ui, setUi] = useState({ errorMessage: null, loading: false });

  const schema = z
    .object({
      email: z
        .string()
        .min(1, t(locale, 'field_required'))
        .email(t(locale, 'enter_valid_email'))
        .toLowerCase()
        .trim(),
      password: z.string().min(6, tt(locale, 'password_min_chars', { min: '6' })),
      policy_consent: z.boolean().refine((value) => value === true, t(locale, 'field_required')),
      subscribe_consent: z.boolean().optional(),
      profiling_consent: z.boolean().optional(),
    })
    .required({
      email: true,
      password: true,
    });

  type Schema = z.infer<typeof schema>;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Schema>({
    resolver: zodResolver(schema),
  });

  const createCustomer = async (user: Schema) => {
    const { subscribe_consent = false, profiling_consent = false } = user;
    setUi({ errorMessage: null, loading: true });
    let errorMessage = null;
    try {
      //@ts-expect-error strictNullChecks
      const response = await client.register(user, { language: locale }, subscribe_consent);

      await client.login(user);
      if (subscribe_consent) {
        await client.subscribeToProfilingOrNewsletter({
          email: user.email,
          metadata: { consent_type: 'marketing' },
        });
      }
      if (profiling_consent) {
        await client.subscribeToProfilingOrNewsletter({
          email: user.email,
          metadata: {
            consent_type: 'profiling',
          },
        });
      }
      onSuccess(user.email);
    } catch (e: any) {
      errorMessage = e.response?.data?.errors[0].detail ?? e.message ?? 'error';
      if (errorMessage.indexOf('already') > -1) {
        errorMessage = t(locale, 'email_already_registered');
      } else if (errorMessage.indexOf('email') > -1) {
        errorMessage = t(locale, 'invalid_email');
      } else if (errorMessage.indexOf('password') > -1) {
        errorMessage = t(locale, 'invalid_password');
      } else {
        errorMessage = t(locale, 'generic_error');
      }
    } finally {
      setUi({ errorMessage, loading: false });
    }
  };

  return (
    <form className="register" onSubmit={handleSubmit(createCustomer)}>
      {ui.errorMessage && <p className="form_error">{ui.errorMessage}</p>}
      <div className="form_field">
        <label className="form_label">{t(locale, 'email')}</label>
        <input
          className="form_input"
          type="email"
          placeholder={t(locale, 'email')}
          {...register('email')}
        />
        <p className="form_error">{errors.email?.message}</p>
      </div>
      <div className="form_field">
        <label className="form_label">{t(locale, 'password')}</label>
        <input
          className="form_input"
          placeholder={t(locale, 'password')}
          type="password"
          autoComplete="off"
          {...register('password')}
        />
        <p className="form_error">{errors.password?.message}</p>
      </div>
      <div className="form__field boolean">
        <label className="form__label boolean">
          <input className="form__input boolean" type="checkbox" {...register('policy_consent')} />
          <span>
            {privacy &&
              privacy.contentBlock.map((block, index) => {
                return <ModularText key={index} block={block} />;
              })}
          </span>
          <p className="form_error">{errors.policy_consent?.message}</p>
        </label>
      </div>
      <div className="form__field boolean">
        <label className="form__label boolean">
          <input
            className="form__input boolean"
            type="checkbox"
            {...register('subscribe_consent')}
          />
          {subscribe &&
            subscribe.contentBlock.map((block, index) => {
              return <ModularText key={index} block={block} />;
            })}
          <p className="form_error">{errors.subscribe_consent?.message}</p>
        </label>
      </div>
      {profiling && profiling.showCheckbox && (
        <div className="form__field boolean">
          <label className="form__label boolean">
            <input
              className="form__input boolean"
              type="checkbox"
              {...register('profiling_consent')}
            />
            {profiling &&
              profiling.contentBlock.map((block, index) => {
                return <ModularText key={index} block={block} />;
              })}
            <p className="form_error">{errors.profiling_consent?.message}</p>
          </label>
        </div>
      )}

      <div className="form__field">
        <button className="button--primary" type="submit" disabled={ui.loading}>
          {t(locale, 'register')}
        </button>
        {ui.loading && <div>{t(locale, 'loading')}</div>}
      </div>
    </form>
  );
};

export default Register;
