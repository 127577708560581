import { ArrowRight } from '@components/icons/ArrowRight';
import { MenuFragment, SiteLocale } from '@graphql/generated';
import { subPaths } from '@lib/utils/pathUtils';
import { useState } from 'react';

type Props = {
  locale: SiteLocale;
  item: MenuFragment;
  baseUrl: string;
  lang: string;
};

export const NavbarItem = ({ locale, item, baseUrl, lang }: Props) => {
  const [isSelected, setSelected] = useState(false);
  function getSlug(item: MenuFragment['children'][0]) {
    let slugPath = '#';
    const basePath = `${baseUrl}/${lang}`;
    if (item.linkType.length == 0) {
      return slugPath;
    }
    const linkType = item.linkType[0];
    if (linkType.__typename == 'InternalLinkRecord') {
      if (!linkType.page || linkType.page.__typename == 'HomepageRecord') {
        return basePath;
      }
      const pageSlug = linkType.page?.slug;
      if (linkType.page.__typename == 'CategoryRecord') {
        slugPath = `${basePath}/${subPaths.categories[locale]}/${pageSlug}`;
      } else if (linkType.page.__typename == 'ProductRecord') {
        slugPath = `${basePath}/${subPaths.products[locale]}/${pageSlug}`;
      } else {
        slugPath = `${basePath}/${pageSlug}`;
      }
    } else {
      slugPath = linkType.url ?? '#';
    }
    return slugPath;
  }
  const path = getSlug(item);
  return (
    <li className={`site-nav__menu__item ${isSelected ? 'selected' : ''}`}>
      <a
        // nolink class is used by masi
        className={`site-nav__menu__link ${item.children.length ? 'nolink' : ''}`}
        href={path}
        onClick={(e) => {
          if (item.children.length > 0) {
            e.preventDefault();
            e.stopPropagation();
            setSelected(!isSelected);
          }
        }}
      >
        {item.titleItem}
        {item.children.length > 0 && <ArrowRight className="rotate-90" />}
      </a>
      {item.children.length > 0 && (
        <ul className="site-nav__menu__sublist">
          {item.children.map((subMenu, index) => (
            <li className="site-nav__menu__item" key={index}>
              <a href={getSlug(subMenu)} title={subMenu.titleItem} className="site-nav__menu__link">
                {subMenu.titleItem}
              </a>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};
