'use client';

import { FeedbackUser } from '@components/account/dialogs/FeedbackUser';
import { CountryDialog } from '@components/dialogs/CountryDialog';
import { FeedbackDialog } from '@components/dialogs/FeedbackDialog';
import { FeedbackDialogQuantity } from '@components/dialogs/FeedbackDialogQuantity';
import { MarketSelector } from '@components/dialogs/MarketSelector';
import { PaymentErrorDialog } from '@components/dialogs/PaymentErrorDialog';
import { SiteLocale, SiteQuery } from '@graphql/generated';
import { useAuth } from '@lib/b2b/authContext';
import { useAppContext } from '@lib/context/appContext';
import t from '@lib/locales';
import { AltsType } from '@model/common';
import { isB2BEnabled } from '@utils/config';
import { useRouter } from 'next/navigation';
import { ReactNode, Suspense, useEffect, useState } from 'react';
import { Authentication } from '..';
import { Footer } from './Footer';
import { Header } from './Header';
import { Snippets } from './Snippets';

type Props = {
  params: { market: string; currency: string; lang: SiteLocale };
  data: SiteQuery;
  cssBodyClass?: string;
  children?: ReactNode;
  showHeader?: boolean;
  alts?: AltsType;
};

export const Layout = ({
  children,
  data,
  showHeader = true,
  params,
  alts,
  cssBodyClass = '',
}: Props) => {
  const { user, loading, logOut } = useAuth();
  const { dispatch } = useAppContext();
  const router = useRouter();
  const { currency, market, lang: locale } = params;
  const baseUrl = `/${market}/${currency}`;
  const [calledPush, setCalledPush] = useState(false);

  useEffect(() => {
    if (isB2BEnabled && !loading && !user) {
      if (calledPush) {
        return; // no need to call router.push() again
      }
      setCalledPush(true);
      router.push('/');
    }
  }, [user, loading]);

  const logOutAndReload = async () => {
    await logOut();
    try {
      router.replace('/');
    } catch (error) {}
  };

  //SAVE CURRENT LOCALE AND BASE URL TO CONTEXT
  useEffect(() => {
    if (locale && baseUrl) {
      dispatch({ type: 'SET_LOCALE', data: locale });
      dispatch({ type: 'SET_BASE_URL', data: baseUrl });
    }
  }, [locale, baseUrl]);

  // ADD CSS CUSTOM CLASSES
  useEffect(() => {
    const body = document.body;
    if (body && cssBodyClass) {
      const classes = cssBodyClass.split(' ').filter(Boolean); // Filtra le stringhe vuote
      classes.forEach((cls) => {
        body.classList.add(cls);
      });
    }

    return () => {
      const body = document.body;
      if (body && cssBodyClass) {
        const classes = cssBodyClass.split(' ').filter(Boolean); // Filtra le stringhe vuote
        classes.forEach((cls) => {
          body.classList.remove(cls);
        });
      }
    };
  }, [cssBodyClass]); // Esegui l'effetto solo quando `cssBodyClass` cambia

  useEffect(() => {
    document.documentElement.lang = locale;
  }, [locale]);

  const logo =
    data.header?.logo?.responsiveImage ||
    data.header?.logo?.url ||
    data.config?.logo?.responsiveImage ||
    data.config?.logo?.url ||
    '';

  return (
    <>
      {isB2BEnabled && user && (
        <div style={{ padding: 4, textAlign: 'right', background: '#f4f4f4' }}>
          <span style={{ marginRight: 10 }}>{user.email}</span>
          <button
            type="button"
            className="button button--primary"
            onClick={async () => await logOutAndReload()}
          >
            {t(locale, 'logout')}
          </button>
        </div>
      )}
      <Header
        baseUrl={baseUrl}
        locale={locale}
        logo={logo}
        menu={data.menu}
        alts={alts}
        showHeader={showHeader}
      />
      <main className="wrap">{children}</main>
      <Footer lang={locale} baseUrl={baseUrl} data={data.footer} fallbackLogo={logo} />

      <Suspense>
        <MarketSelector locale={locale} />
      </Suspense>
      <Authentication locale={locale} data={data} />
      <FeedbackDialog locale={locale} baseUrl={baseUrl} />
      <FeedbackUser locale={locale} baseUrl={baseUrl} />
      <FeedbackDialogQuantity locale={locale} baseUrl={baseUrl} />
      <Suspense>
        <CountryDialog locale={locale} />
      </Suspense>
      <PaymentErrorDialog locale={locale} />
      <Snippets locale={locale} />
    </>
  );
};
