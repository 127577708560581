import { Loading } from '@components/layout/Loading';
import { SiteLocale } from '@graphql/generated';
import { useAppContext } from '@lib/context/appContext';
import t, { Labels } from '@lib/locales';
import {
  filterCountries,
  filterMarketByCountryCode,
  getMarketFromPathname,
  getPathFromMarket,
} from '@lib/utils/clUtils';
import { getLocales, isHome } from '@lib/utils/pathUtils';
import { Locales } from '@model/common';
import { COUNTRY_CODE_KEY } from '@shared/constants';
import {
  deleteCartIdSession,
  deleteUserSession,
  localStorageGetItem,
  localStorageSetItem,
} from '@utils/local-storage';
import _ from 'lodash';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';

type Props = {
  locale: SiteLocale;
};

export const MarketSelector = ({ locale }: Props) => {
  const { state, dispatch, client } = useAppContext();
  const {
    countryCode: promotedCountry,
    countryModal: isActive,
    marketId: stateMarketId,
    order: stateOrder,
  } = state;
  const [country, setCountry] = useState(promotedCountry);
  const [options] = useState(() => filterCountries(promotedCountry));
  const [currency, setCurrency] = useState('');
  const [currencyOptions, setCurrencyOptions] = useState<string[] | null>(null);
  const [selectedLocale, setSelectedLocale] = useState(locale);
  const [loading, setLoading] = useState(false);
  const locales = getLocales();
  const pathname = usePathname();
  const router = useRouter();
  const searchParams = useSearchParams();

  function redirect(marketId: string, locale: SiteLocale) {
    if (!marketId) {
      return;
    }
    const redirectUrl = getPathFromMarket(marketId, locale);
    if (pathname.indexOf(redirectUrl || '') < 0) {
      const search = searchParams.toString();
      const url = `${redirectUrl}${search ? `?${search}` : ''}`;
      router.push(url);
    } else {
      setLoading(false);
    }
  }

  //on market selected
  function handleMarketChange(marketId: string, countryCode: string, locale: SiteLocale) {
    localStorageSetItem(COUNTRY_CODE_KEY, countryCode);

    if (state.marketId == marketId && state.countryCode == countryCode && state.locale == locale) {
      return;
    }
    setLoading(true);

    // delete cart id and user session because they are related to the market id
    if (client) {
      client.cleanup();
    } else {
      deleteCartIdSession();
      deleteUserSession();
    }
    dispatch({ type: 'SET_ORDER', data: null });

    redirect(marketId, locale);

    dispatch({ type: 'INIT_MARKET', data: { marketId, countryCode } });
  }

  useEffect(() => {
    // if marketId is setted, the state is ready
    if (stateMarketId) {
      const savedCountryCode = localStorageGetItem(COUNTRY_CODE_KEY);
      if (!savedCountryCode) {
        // if country code is not setted, is the first time that the user is visiting the site
        //check if have multiple market setted
        if (state.hasMultipleCountries) {
          //dispatch modal only if path is home
          if (isHome(pathname)) {
            // DIALOG
            dispatch({ type: 'TOGGLE_COUNTRY_MODAL', data: true });
          } else {
            const urlMarket = getMarketFromPathname(pathname);
            if (urlMarket) {
              // CHANGE TO MARKET IN CURRENT URL
              handleMarketChange(urlMarket.marketId, urlMarket.countryCode, locale);
            }
          }
        }
      }
    }
  }, [stateMarketId]);

  useEffect(() => {
    setCountry(promotedCountry);
  }, [promotedCountry]);

  useEffect(() => {
    if (promotedCountry && stateOrder?.country_code) {
      // if country code visited is different from order country code
      if (promotedCountry != stateOrder.country_code) {
        //dispatch modal for confirm the change and remove the order from session
        dispatch({
          type: 'TOGGLE_FEEDBACK_MODAL_COUNTRY',
          data: { newCountry: promotedCountry, oldCountry: stateOrder.country_code },
        });
      }
    }
  }, [promotedCountry, stateOrder?.country_code]);

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    if (value) {
      setCountry(value);
      const m = filterMarketByCountryCode(value);
      const currencies = m.reduce((all: string[], m) => {
        return _.uniq([...all, m.currency]);
      }, []);
      setCurrency('');
      setCurrencyOptions(currencies && currencies.length > 1 ? currencies : null);
    }
  };

  const handleClose = () => {
    dispatch({ type: 'TOGGLE_COUNTRY_MODAL', data: false });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!country) {
      return;
    }
    const m = filterMarketByCountryCode(country);
    let market = m[0];
    if (country && currency) {
      market = m.find((i) => i.currency === currency);
    }
    if (market) {
      handleMarketChange(market.market_id, country, selectedLocale);
      handleClose();
    }
  };

  return (
    <>
      {loading && <Loading locale={selectedLocale} />}
      <div className={`market-dialog ${isActive ? 'is-active' : ''}`}>
        <div className="market-dialog__frame">
          <div className="market-dialog__header">{t(locale, 'choose_shipping_country')}</div>

          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="market-dialog__body">
              <div className="market-dialog__select">
                <select
                  title={t(locale, 'choose_shipping_country')}
                  className="market-dialog__select__inner"
                  onChange={(e) => handleChange(e)}
                  value={country}
                >
                  {!promotedCountry && (
                    <option value="">{t(locale, 'choose_shipping_country')}</option>
                  )}

                  {options
                    .map((ops) => {
                      return {
                        code: ops.value,
                        label: t(locale, `countries.${ops.value}` as Labels),
                      };
                    })
                    .sort((a, b) => (a.label > b.label ? 1 : -1))
                    .map((o) => (
                      <option key={o.code} value={o.code}>
                        {o.label}
                      </option>
                    ))}
                </select>
              </div>

              {currencyOptions && (
                <div className="market-dialog__select">
                  <select
                    title="currency"
                    className="market-dialog__select__inner"
                    onChange={(e) => setCurrency(e.target.value)}
                    value={currency}
                  >
                    {currencyOptions.map((currency) => (
                      <option key={currency} value={currency}>
                        {currency}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {locales && (
                <>
                  <div className="market-dialog__header"> {t(locale, 'choose_locale')}</div>
                  <div className="market-dialog__select">
                    <select
                      title={t(locale, 'choose_locale')}
                      className="market-dialog__select__inner"
                      value={selectedLocale}
                      onChange={(e) => setSelectedLocale(e.target.value as SiteLocale)}
                    >
                      {locales.map((locale) => (
                        <option key={locale} value={locale}>
                          {t(locale as Locales, locale as Labels)}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              )}
              <div className="dialog__footer">
                <button type="submit" className="button--primary">
                  {t(locale, 'select')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
