import { SiteLocale } from '@graphql/generated';
import { zodResolver } from '@hookform/resolvers/zod';
import { AppCommerceLayerClient } from '@lib/AppCommerceLayerClient';
import t from '@lib/locales';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

type Props = {
  client: AppCommerceLayerClient;
  locale: SiteLocale;
  baseUrl: string;
};

const RecoverPassword = ({ client, locale, baseUrl }: Props) => {
  const [ui, setUi] = useState({ errorMessage: null, loading: true });
  const [success, setSuccess] = useState(false);

  const schema = z
    .object({
      email: z
        .string()
        .min(1, t(locale, 'field_required'))
        .email(t(locale, 'enter_valid_email'))
        .toLowerCase()
        .trim(),
    })
    .required({
      email: true,
    });

  type Schema = z.infer<typeof schema>;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Schema>({
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    setUi({ errorMessage: null, loading: false });
  }, []);

  const getResetPasswordUrl = () => {
    return `${process.env.HOST}${baseUrl}/${locale}/reset-password`;
  };

  const resetPassword = async ({ email }: Schema) => {
    setUi({ errorMessage: null, loading: true });
    let errorMessage = null;
    try {
      const url = getResetPasswordUrl();

      const result = await client.resetPassword({
        email,
        url,
        language: locale,
      });
      setSuccess(true);
    } catch (error) {
      errorMessage = t(locale, 'step_error');
    } finally {
      setUi({ errorMessage, loading: false });
    }
  };

  if (success) {
    return <p>{t(locale, 'forgot_disclaimer')}</p>;
  }

  return (
    <form className="recover" onSubmit={handleSubmit(resetPassword)}>
      <p>{t(locale, 'forgot_disclaimer')}</p>
      {ui.errorMessage && <p className="form_error">{ui.errorMessage}</p>}
      <div className="form_field">
        <input
          type="email"
          placeholder={t(locale, 'email')}
          {...register('email')}
          className="form_input"
        />
        <p className="form_error">{errors.email?.message}</p>
      </div>
      <button className="button--primary" type="submit" disabled={ui.loading}>
        {t(locale, 'send')}
      </button>
      {ui.loading && <div> {t(locale, 'loading')}</div>}
    </form>
  );
};

export default RecoverPassword;
