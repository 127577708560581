import { DatoImage } from '@components/image/DatoImage';
import { FooterFragment, ResponsiveImageFragment } from '@graphql/generated';

type Props = {
  lang: string;
  baseUrl: string;
  data: FooterFragment;
  fallbackLogo: ResponsiveImageFragment | string;
};

export const Footer = ({ lang, baseUrl, data, fallbackLogo }: Props) => {
  const localizedUrl = `${baseUrl}/${lang}`;
  const footerLinks = data.footerBlocks || [];
  const logo = data.logo?.responsiveImage ?? fallbackLogo;
  return (
    <footer className="footer">
      <div className="footer__main">
        <div className="footer__logo">
          <a href={localizedUrl} aria-label="home">
            {logo && typeof logo == 'string' && <img src={logo} alt="home" />}
            {logo && typeof logo != 'string' && <DatoImage data={logo} />}
          </a>
        </div>
        <div className="footer__blocks">
          {footerLinks.map((footerLink, index) => (
            <div className="footer__blocks__item" key={index}>
              <h4 className="footer__blocks__item__title">{footerLink.title}</h4>
              <div
                className="footer__blocks__item__content"
                dangerouslySetInnerHTML={{ __html: footerLink.content }}
              />
              {footerLink.link.map((link) => {
                const { __typename: type } = link;
                let url = '#';
                let title = '';
                if (type === 'InternalLinkRecord') {
                  title = link.title;
                  if (link?.page?.__typename != 'HomepageRecord') {
                    url = `${localizedUrl}/${link?.page?.slug ?? '#'}`;
                  }
                } else {
                  url = link.url;
                  title = link.title;
                }

                return (
                  <div className="footer__blocks__item__link" key={link.id}>
                    <a href={url}>{title}</a>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    </footer>
  );
};
