import { CartIcon } from '@components/icons/CartIcon';
import { useAppContext } from '@lib/context/appContext';
import { useEffect } from 'react';

type Props = {
  baseUrl: string;
  locale: string;
};

export const CartToggle = ({ baseUrl, locale }: Props) => {
  const { client, dispatch, state } = useAppContext();

  const checkItems = async () => {
    const order = state.order ?? (await client.getOrder());
    dispatch({ type: 'SET_ORDER', data: order });
    const cartItems = client.countOrderProductsQuantity(order);
    dispatch({ type: 'SET_NUM_ITEMS', data: cartItems });
  };

  useEffect(() => {
    if (client) {
      checkItems();
    }
  }, [client]);

  return (
    <div className="cart_nav">
      <a href={`${baseUrl}/${locale}/cart`} className="cart_link" title="cart">
        <CartIcon width="28px" height="28px" />
        <span className="cart_number">{state?.numItems}</span>
      </a>
    </div>
  );
};

export default CartToggle;
